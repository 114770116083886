import {
  Box,
  Flex,
  Logo,
  css,
  Container,
  Text,
  HamburgerIcon,
  useSpring,
  animated,
  SpaceChildren,
  NavLinkDesktop,
  NavLinkMobile,
  LineOverlay,
  useTransition
} from '@sitewerk/juraholzbau-design-system'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import React, { useEffect, useState } from 'react'
import * as sanity from '../lib/sanity'
import {
  Menu,
  Quote,
  PageHeaderBaseModule,
  PageSliderModule
} from '../lib/sanity-types'
import { useToggle, useInterval } from '../lib/hooks'
import { BasicRichText } from './basic-rich-text'
import { SwissWoodSvg } from './icons'
import { Button } from '@sitewerk/juraholzbau-design-system/src/button'
import useScrollLock from 'use-scroll-lock'
import { HeaderFeature } from './header-feature'
import { Link } from 'gatsby'
import { useConfiguratorContext } from './configurator-context'
import useHover from 'react-use-hover'

const Sidebar: React.FC<{
  isOpen: boolean
  menu: Menu
  openConfigurator: (path: string[]) => void
}> = ({ openConfigurator, isOpen, menu }) => {
  const ref = React.useRef(null)
  useScrollLock(isOpen, ref)

  return (
    <Box
      ref={ref}
      zIndex={20}
      width={1}
      css={css({
        pointerEvents: isOpen ? 'visible' : 'none',
        overflowX: 'hidden',
        height: '100vh',
        position: 'absolute',
        top: 0
      })}
    >
      <Box
        transitionDuration={0}
        transitionTimingFunction={isOpen ? 'acelerate' : 'decelerate'}
        bg="neutral.200"
        style={{
          height: '100%',
          transform: `translateX(${isOpen ? 0 : '101%'})`
        }}
      >
        <Logo py={5} hidden />
        <LineOverlay bg="dark" />

        <Box
          position="absolute"
          bottom={0}
          left={0}
          textColor="white"
          width="4em"
          m={5}
        >
          <SwissWoodSvg />
        </Box>

        <Box ml="calc(3rem + 5vw)">
          <SpaceChildren direction="top" size={2}>
            {menu.items.map((item, i) => (
              <NavLinkMobile key={i} to={item.pageUrl}>
                {item.title}
              </NavLinkMobile>
            ))}
          </SpaceChildren>

          <Button onClick={() => openConfigurator([])} type="primary" mt={5}>
            {menu.cta.title}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const AnimatedImg = animated(Img)

export const Header: React.FC<
  PageHeaderBaseModule & {
    menu: Menu
    quotes: Quote[]
  }
> = ({ feature, menu, quotes, type, ...headerProps }) => {
  const [isNavOpen, toggleIsNavOpen] = useToggle(false)
  const { openConfigurator } = useConfiguratorContext()
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 })

  const slideDown = useSpring({
    from: { y: -30, opacity: 0 },
    y: 0,
    opacity: 1
  })

  const getBaseColor = type =>
    (type == 'primary' && 'white') ||
    (['secondary', 'detail'].includes(type) && 'neutral.200')

  const baseColor = getBaseColor(type)

  const [logoColor, setLogoColor] = useState<string>(baseColor)

  useEffect(() => {
    if (isNavOpen) {
      setTimeout(() => {
        setLogoColor(getBaseColor('primary'))
      }, 100)
    } else {
      setLogoColor(baseColor)
    }
  }, [isNavOpen])

  const [index, setIndex] = useState(0)

  const slideImageTransitions = useTransition(index, p => p, {
    initial: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  })

  const slideTextTransitions = useTransition(index, p => p, {
    initial: { transform: 'translateX(-100px)' },
    from: { transform: 'translateX(-100px)' },
    enter: { transform: 'translateX(0px)' },
    leave: { opacity: 0 }
  })

  useInterval(() => {
    if (!isHovering) {
      setIndex(
        state =>
          (state + 1) % (headerProps as PageSliderModule).slideshow.length
      )
    }
  }, 5000)

  return (
    <>
      <Sidebar
        menu={menu}
        isOpen={isNavOpen}
        openConfigurator={openConfigurator}
      />
      <Box
        borderBottomColor="neutral.600"
        borderBottomStyle={type == 'secondary' ? 'solid' : null}
        borderWidth={1}
        minHeight={
          (type == 'primary' && '85vh') || (type == 'secondary' && '65vh')
        }
      >
        <Box minHeight="inherit" position="relative" overflow="hidden">
          <Box
            position="absolute"
            bottom={0}
            left={0}
            width={1}
            display={['none', null, null, 'block']}
          >
            <Container>
              <Flex justifyContent="flex-end" display="inline-block">
                {feature == 'quotes' && (
                  <HeaderFeature bg="white" items={quotes} />
                )}
              </Flex>
            </Container>
          </Box>
          <Box position="absolute" zIndex={30} width={1} p={5} top={0} left={0}>
            <Container>
              <animated.div
                style={{
                  opacity: slideDown.opacity,
                  transform: slideDown.y.interpolate(y => `translateY(${y}px)`)
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Link to="/">
                    <Logo textColor={logoColor} />
                  </Link>
                  <Box display={['block', null, null, 'none']}>
                    <HamburgerIcon
                      color={isNavOpen ? getBaseColor('primary') : baseColor}
                      isOpen={isNavOpen}
                      lineWidth={30}
                      lineHeight={1}
                      lineSpacing={6}
                      onPress={() => toggleIsNavOpen()}
                    />
                  </Box>

                  <Box display={['none', null, null, 'block']}>
                    <SpaceChildren direction="left" size={4}>
                      {menu.items.map((item, i) => (
                        <NavLinkDesktop
                          key={i}
                          to={item.pageUrl}
                          color={baseColor}
                        >
                          {item.title}
                        </NavLinkDesktop>
                      ))}
                    </SpaceChildren>
                  </Box>

                  <Box display={['none', null, null, 'block']}>
                    <Flex alignItems="center">
                      <Box textColor={baseColor} width="4rem">
                        <SwissWoodSvg />
                      </Box>
                      <Button
                        onClick={() => openConfigurator([])}
                        type="primary"
                        ml={4}
                      >
                        {menu.cta.title}
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </animated.div>
            </Container>
          </Box>
          <Flex flexDirection="column" minHeight="inherit" height="100%">
            <Logo py={5} hidden />

            <Flex
              my={[3, null, null, 7]}
              alignItems="center"
              flexDirection={type == 'secondary' && ['column', null, 'row']}
              flex="1"
              overflow="hidden"
              ml="calc(3rem + 5vw)"
            >
              <Box pb={4} position="relative" height={['400px', null, '600px']}>
                {slideTextTransitions.map(({ item, props, key }) => {
                  const slide = (headerProps as PageSliderModule).slideshow[
                    item
                  ]

                  return (
                    <animated.div key={key} style={props}>
                      <Box {...hoverProps}>
                        {/* 
                  // @ts-ignore */}
                        <Text
                          as="h1"
                          m={0}
                          pr={5}
                          textColor={baseColor}
                          fontWeight={600}
                          css={css((theme: any) => ({
                            fontSize: `calc(${theme.space[4]} + ${theme.fontSizeFluid[1]})`,
                            width: '15ch'
                          }))}
                        >
                          {slide.title}
                        </Text>

                        <BasicRichText
                          blocks={slide.description}
                          TextElement={props => {
                            // @ts-ignore
                            return (
                              <Text
                                as="h2"
                                m={0}
                                mt={[3, 5]}
                                pr={5}
                                textColor={
                                  (type == 'primary' && 'neutral.600') ||
                                  (type == 'secondary' && 'neutral.200')
                                }
                                fontWeight={300}
                                fontSize={[2, 3]}
                                minWidth="55ch"
                                maxWidth="55ch"
                                lineHeight={[1, 2]}
                                {...props}
                              />
                            )
                          }}
                        />
                      </Box>
                    </animated.div>
                  )
                })}
              </Box>

              {type == 'secondary' && (
                <Box width={1} alignSelf="stretch" ml={[0, null, 6]}>
                  <Img
                    style={{
                      height: '100%'
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    fluid={getFluidGatsbyImage(
                      (headerProps as PageSliderModule).slideshow[0].image
                        .asset,
                      { maxWidth: 1920 },
                      sanity.config
                    )}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
          {type == 'primary' && (
            <Box>
              {slideImageTransitions.map(({ item, props, key }) => {
                const slide = (headerProps as PageSliderModule).slideshow[item]
                return (
                  <AnimatedImg
                    key={key}
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      height: '100%',
                      width: '100%',
                      zIndex: -1,
                      ...props
                    }}
                    objectFit="cover"
                    objectPosition="50% 30%"
                    fluid={getFluidGatsbyImage(
                      slide.image.asset,
                      { maxWidth: 1920 },
                      sanity.config
                    )}
                  />
                )
              })}
            </Box>
          )}
          <LineOverlay
            bg={
              (type == 'primary' && 'dark') ||
              (type == 'secondary' && 'light') ||
              'dark'
            }
          />
          {type == 'primary' && (
            <Box
              position="absolute"
              bottom={0}
              m={5}
              right={0}
              textColor={baseColor}
              width="4rem"
              display={['block', null, null, 'none']}
            >
              <SwissWoodSvg />
            </Box>
          )}
        </Box>

        {type == 'primary' && (
          <Box display={['block', null, null, 'none']}>
            <Button
              fullWidth
              onClick={() => openConfigurator([])}
              type="primary"
            >
              {menu.cta.title}
            </Button>
          </Box>
        )}
      </Box>

      <Box display={['block', null, null, 'none']}>
        {feature == 'quotes' && (
          <HeaderFeature
            borderBottom="1px solid"
            borderColor="neutral.700"
            items={quotes}
          />
        )}
      </Box>
    </>
  )
}
