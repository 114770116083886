import React from 'react'
import {
  Box,
  Container,
  MainHeadingWithDescription
} from '@sitewerk/juraholzbau-design-system'
import { PageContext } from '../lib/sanity-types'
import { Layout } from '../components/layout'
import { Header } from '../components/header'
import { ServiceOverview } from '../components/service-overview'
import { ContentGrid, transformForGrid } from '../components/content-grid'
import { ConfiguratorFooter } from '../components/configurator-footer'
import { RichText } from '../components/rich-text'
import { FadeInContainer } from '../components/fade-in-container'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Page: React.FC<{ pageContext: PageContext }> = ({
  pageContext: { settings, page, menu, quotes, services }
}) => {
  return (
    <Layout seo={page.seo} settings={settings} menu={menu}>
      {page.template.modules.map(module => {
        switch (module._type) {
          case 'pageHeader': {
            return (
              <Header
                key={module._key}
                menu={menu}
                quotes={quotes}
                feature={module.feature}
                slideshow={[
                  {
                    title: module.title,
                    description: module.description,
                    image: module.bgs[0]
                  }
                ]}
              />
            )
          }

          case 'pageSliderHeader': {
            return (
              <Header
                key={module._key}
                menu={menu}
                quotes={quotes}
                {...module}
              />
            )
          }

          case 'pageServiceOverview': {
            return (
              <ServiceOverview
                key={module._key}
                services={services}
                {...module}
              />
            )
          }

          case 'pageContent': {
            return (
              <>
                <MainHeadingWithDescription
                  isLarge={true}
                  title={module.richText[0].children[0].text}
                  description={module.richText.slice(1, module.richText.length)}
                />
                <FadeInContainer>
                  <Container>
                    <LiteYouTubeEmbed
                      id="8YJgMCNcf-s"
                      title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                    />
                  </Container>
                </FadeInContainer>
              </>
            )
          }

          case 'pageContentGrid': {
            return (
              <ContentGrid
                key={module._key}
                {...module}
                items={transformForGrid(module.items)}
              />
            )
          }
        }
      })}

      <Box bg="white">
        <ConfiguratorFooter services={services} />
      </Box>
    </Layout>
  )
}

export default Page
